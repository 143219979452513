import { useMemo, useState } from "react";
import axios from "../../axios";
import Header from "../../components/header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import Slider from "@mui/material/Slider";
import CustomPagination from "../../components/customPagination";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import UserFilterSelectInput from "../../components/userFilterSelectInput";
import CourseCard from "../../components/courseCard";
import TextInput from "../../components/textInput";
import AlertPopup from "../../components/alertPopup";

const HomePage = () => {
  const [searchInput, setSearchInput] = useState("");
  const [filterData, setFilterData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [priceRange, setPriceRange] = useState([0, 100000]);
  const [level, setLevel] = useState("");
  const [location, setLocation] = useState("");
  const [provider, SetProvider] = useState("");
  const [category, setCategory] = useState("");
  const [sortByName, setSortByName] = useState("title");
  const [sortByPrice, setSortByPrice] = useState("");
  const [selectedTap, setSelectedTab] = useState("all");
  const [openPopup, setOpenPopup] = useState(false);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState("");
  useMemo(() => {
    setLoading(true);
    const getCoursesData = async () => {
      await axios
        .get(
          `${axios.defaults.baseURL}/users/courses?status=${selectedTap}&filter[category_id]=${category}&filter[provider_id]=${provider}&filter[title]=${searchInput}&filter[level_id]=${level}&filter[location]=${location}&price_min=${priceRange[0]}&price_max=${priceRange[1]}&sort=${sortByName},${sortByPrice}&page=${page}`,
          {
            headers: {
              authorization: axios.defaults.token,
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            setAllData(response.data.data);
            setLoading(false);
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.message === "Invalid ability provided.") {
              setLoading(false);
            }
            console.log(error.response);
            console.log("server responded");
          } else if (error.request) {
            console.log("network error");
          } else {
            console.log(error);
          }
        });
    };
    const getFiltersData = async () => {
      await axios
        .get(`${axios.defaults.baseURL}/courses/getFilterLists`, {
          headers: {
            authorization: axios.defaults.token,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            setFilterData(response.data.data);
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.message === "Invalid ability provided.") {
              setLoading(false);
            }
            console.log(error.response);
            console.log("server responded");
          } else if (error.request) {
            console.log("network error");
          } else {
            console.log(error);
          }
        });
    };
    getFiltersData();
    getCoursesData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    page,
    selectedTap,
    searchInput,
    priceRange,
    level,
    category,
    provider,
    location,
    sortByName,
    sortByPrice,
    status,
  ]);
  const sortNameOption = [
    {
      name: "asc",
      value: "title",
    },
    {
      name: "desc",
      value: "-title",
    },
  ];
  const sortPriceOption = [
    {
      name: "price",
      value: "",
    },
    {
      name: "lowest",
      value: "-price",
    },
    {
      name: "highest",
      value: "price",
    },
  ];
  const handlePageChange = (e) => {
    setPage(e);
  };
  const handleSortName = (e) => {
    setSortByName(e);
  };
  const handleSortPrice = (e) => {
    setSortByPrice(e);
  };
  const handleNav = (page) => {
    setSelectedTab(page);
    setPage(1);
  };

  const providerHandler = (e) => {
    SetProvider(e.target.value);
    setPage(1);
  };
  const priceHandler = (e, value) => {
    setPriceRange(value);
    setPage(1);
  };
  const showAlertPopup = (status, message) => {
    handlePopup(true);
    setMessage(message);
    setStatus(status);
  };
  const handlePopup = (value) => {
    setOpenPopup(value);
  };
  return (
    <div>
      <Header showLogo={true} />
      <div className="userPageTitleContainer">
        <img
          className="wave1"
          src={require("../../assets/images/wave1.svg").default}
          alt=""
        />
        <h1>All Courses</h1>
        <img
          className="wave2"
          src={require("../../assets/images/wave2.svg").default}
          alt=""
        />
      </div>
      <div className="userCoursesPageContainer">
        {/* LEFT SIDE */}
        <div className="filterContainer">
          <p style={{ fontSize: "20px", fontWeight: "bold" }}>Filter Courses</p>
          <div className="userFilterInputsWrapper">
            <div className="filterSearchInputContainer">
              <p className="filterInputTitle">Search</p>
              <div style={{ position: "relative" }}>
                <FontAwesomeIcon icon={faSearch} className="searchIcon" />
                <input
                  type="text"
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                  className="userSearchInput"
                  placeholder="Search course by name"
                />
              </div>
            </div>
            {filterData.prices && (
              <div className="userFilterInputsContainer">
                <div className="userFilterGroup">
                  <p className="filterInputTitle">Price</p>
                  <div className="priceRangeContainer">
                    <p>{filterData.prices[0].priceFrom} EGP</p>
                    <p>{filterData.prices[0].priceTo} EGP</p>
                  </div>
                  <div style={{ padding: "0px 8px" }}>
                    <Slider
                      value={priceRange}
                      onChangeCommitted={(e, value) => {
                        priceHandler(e, value);
                        setPage(1);
                      }}
                      valueLabelDisplay="on"
                      min={filterData.prices[0].priceFrom}
                      max={filterData.prices[0].priceTo}
                    />
                  </div>
                </div>
                <div className="userFilterGroup">
                  <p className="filterInputTitle">Level</p>
                  {filterData.levels && (
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={level}
                      onChange={(event) => {
                        setLevel(event.target.value);
                        setPage(1);
                      }}
                    >
                      {filterData.levels.map((level) => {
                        return (
                          <FormControlLabel
                            key={level.id}
                            value={level.id}
                            control={<Radio />}
                            label={level.name}
                          />
                        );
                      })}
                    </RadioGroup>
                  )}
                </div>
                <div className="userFilterGroup">
                  <p className="filterInputTitle">Category</p>
                  {filterData.categories && (
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={category}
                      onChange={(event) => {
                        setCategory(event.target.value);
                        setPage(1);
                      }}
                    >
                      {filterData.categories.map((category) => {
                        return (
                          <FormControlLabel
                            key={category.id}
                            value={category.id}
                            control={<Radio />}
                            label={category.name}
                          />
                        );
                      })}
                    </RadioGroup>
                  )}
                </div>
                <div className="userFilterGroup">
                  <p className="filterInputTitle">Provider</p>

                  {filterData.providers && (
                    <UserFilterSelectInput
                      value={provider}
                      options={filterData.providers}
                      onChange={(e) => providerHandler(e)}
                    />
                  )}
                </div>
                <div className="userFilterGroup">
                  <p className="filterInputTitle">Location</p>
                  <TextInput
                    style={{ marginTop: "5px" }}
                    value={location}
                    onChange={(e) => {
                      setLocation(e.target.value);
                      setPage(1);
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        {/* RIGHT SIDE */}
        <>
          <div className="contentContainer">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div
                style={{
                  display: "flex",
                  border: "1px solid var(--c300)",
                  borderRadius: "50px",
                  width: "fit-content",
                  backgroundColor: "#F4F4F6",
                }}
              >
                <button
                  className={
                    selectedTap === "all" ? "selectedTap" : "notSelectedTap"
                  }
                  onClick={() => handleNav("all")}
                >
                  All courses
                </button>
                <button
                  className={
                    selectedTap === "approved"
                      ? "selectedTap"
                      : "notSelectedTap"
                  }
                  onClick={() => handleNav("approved")}
                >
                  My courses
                </button>
                <button
                  className={
                    selectedTap === "pending" ? "selectedTap" : "notSelectedTap"
                  }
                  onClick={() => handleNav("pending")}
                >
                  pending
                </button>
                <button
                  className={
                    selectedTap === "rejected"
                      ? "selectedTap"
                      : "notSelectedTap"
                  }
                  onClick={() => handleNav("rejected")}
                >
                  rejected
                </button>
              </div>
              <div>
                <select
                  className="filterSelectInput"
                  style={{ width: "60px", border: "none" }}
                  onChange={(e) => handleSortName(e.target.value, "name")}
                >
                  {sortNameOption.map((option) => {
                    return (
                      <option key={option.name} value={option.value}>
                        {option.name}
                      </option>
                    );
                  })}
                </select>
                <select
                  className="filterSelectInput"
                  style={{ width: "70px", border: "none" }}
                  onChange={(e) => handleSortPrice(e.target.value)}
                >
                  {sortPriceOption.map((option) => {
                    return (
                      <option key={option.name} value={option.value}>
                        {option.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            {!loading ? (
              <>
                <div className="userPageContent">
                  {allData.paginatedData.map((course) => (
                    <CourseCard
                      key={course.id}
                      course={course}
                      showAlertPopup={showAlertPopup}
                    />
                  ))}
                </div>
                <CustomPagination
                  pageNum={page}
                  handlePageChange={handlePageChange}
                  lastPage={allData.lastPage}
                />
              </>
            ) : (
              <p
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "white",
                  height: "500px",
                }}
              >
                loading...
              </p>
            )}
            {openPopup ? (
              <AlertPopup
                title={status}
                subTitle={message}
                openPopup={handlePopup}
              />
            ) : null}
          </div>
        </>
      </div>
    </div>
  );
};

export default HomePage;
