import axios from "../../axios";
import { useMemo, useState } from "react";
import SideBar from "../../components/sideBar/sideBar";
import Header from "../../components/header";
import TitleHeader from "../../components/titleHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AlertPopup from "../../components/alertPopup";
import CustomPagination from "../../components/customPagination";
import {
  faFilter,
  faSearch,
  faEllipsisVertical,
} from "@fortawesome/free-solid-svg-icons";
import DeleteModal from "../../components/modals/deleteModal";
import ProviderFormModal from "../../components/modals/providerFormModal";
import BlankPage from "../../components/blankPage";
const ProvidersPage = () => {
  const [allProvidersData, setAllProvidersData] = useState([]);
  const [showAddProvider, setShowAddProvider] = useState(false);
  const [showEditProvider, setShowEditProvider] = useState(false);
  const [message, setMessage] = useState("");
  const [providerID, setProviderID] = useState("");
  const [status, setStatus] = useState("");
  const [openPopup, setOpenPopup] = useState(false);
  const [openDeleteProvider, setOpenDeleteProvider] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [page, setPage] = useState(1);
  const [allData, setAllData] = useState([]);
  const [loading, setLoading] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [showAddButton, setShowAddButton] = useState(true);
  const [hideTable, setHideTable] = useState(false);

  useMemo(() => {
    // if (axios.defaults.permissions) {
    //   if (axios.defaults.permissions.find((e) => e.name === "create_Provider")) {
    //     setShowAddButton(true);
    //   }
    // }
    const getProviders = async () => {
      let url = `${axios.defaults.baseURL}/courses/providers?filter[name]=${searchInput}&page=${page}`;
      await axios
        .get(url, {
          headers: {
            authorization: axios.defaults.token,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            setLoading(false);
            setAllData(response.data.data);
            setAllProvidersData(response.data.data.paginatedData);
            setProviderID("");
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.message === "Invalid ability provided.") {
              setLoading(false);
              setHideTable(true);
            }
            console.log(error.response);
            console.log("server responded");
          } else if (error.request) {
            console.log("network error");
          } else {
            console.log(error);
          }
        });
    };
    getProviders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showAddProvider, openPopup, searchInput, page]);
  const addNewProvider = () => {
    setShowAddProvider((current) => !current);
  };
  const closeModal = () => {
    setShowAddProvider(false);
    setShowEditProvider(false);
    setProviderID("");
  };
  const showAlertPopup = (status, message) => {
    handlePopup(true);
    setMessage(message);
    setStatus(status);
  };
  const handlePopup = (value) => {
    setOpenPopup(value);
  };
  const handleEditButton = (id) => {
    setProviderID(id);
    setShowEditProvider(true);
  };
  const handleDeleteButton = (id) => {
    deleteModal();
    setProviderID(id);
  };
  const deleteModal = () => {
    setOpenDeleteProvider((current) => !current);
  };

  const handlePageChange = (e) => {
    setPage(e);
  };
  return (
    <div style={{ display: "flex" }}>
      <SideBar openCourses={true} />
      {/* mainContainer*/}
      <div className="mainContainer">
        <Header />
        {loading ? (
          <p
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "white",
              height: "500px",
            }}
          >
            loading...
          </p>
        ) : !allProvidersData.length && !loading && !searchInput ? (
          <div style={{ backgroundColor: "white" }}>
            <TitleHeader
              title="Manage Providers"
              subTitle="Providers"
              showAddButton={false}
              mainButtonFunction={addNewProvider}
              counterNumber={allData.total}
            />
            <BlankPage
              mainButton="Add New"
              mainText="Let’s get you started!"
              paragraph="You can add a new provider or upload multiple providers at once."
              showAddButton={showAddButton}
              mainButtonFunction={addNewProvider}
            />
          </div>
        ) : (
          <div>
            <TitleHeader
              title="Manage Providers"
              subTitle="Providers"
              mainButton="Add New"
              showAddButton={showAddButton}
              mainButtonFunction={addNewProvider}
              secondaryButton="Upload"
              hideSecondaryButton
              counterNumber={allData.total}
            />
            {!hideTable ? (
              <>
                <div className="filterHeader">
                  <div style={{ padding: "0 30px", display: "flex" }}>
                    <div style={{ position: "relative"}}>
                      <FontAwesomeIcon icon={faSearch} className="searchIcon" />
                      <input
                        type="text"
                        value={searchInput}
                        onChange={(e) => setSearchInput(e.target.value)}
                        className="searchInput"
                        placeholder="Search Providers"
                      />
                    </div>
                  </div>
                </div>
                <div className="mainTable">
                  <table style={{ width: "100%" }}>
                    <tbody>
                      <tr className="tableHeaderContainer">
                        <th style={{ paddingLeft: "30px", textAlign: "start" }}>
                        Provider Name
                        </th>
                        <th style={{ paddingRight: "30px", textAlign: "end" }}>
                          Actions
                        </th>
                      </tr>
                      {allProvidersData.map((e) => {
                        return (
                          <tr key={e.id}>
                            <td
                              style={{
                                paddingLeft: "30px",
                                textAlign: "start",
                              }}
                            >
                              {e.name}
                            </td>
                            <td>
                              <div
                                className="actionWrapper"
                                style={{ marginRight: "30px" }}
                              >
                                <FontAwesomeIcon icon={faEllipsisVertical} />
                                <div className="actionButtonsContainer">
                                  <button
                                    onClick={() => handleEditButton(e.id)}
                                    className="actionButton"
                                  >
                                    edit
                                  </button>

                                  <button
                                    onClick={() => handleDeleteButton(e.id)}
                                    className="actionButton"
                                    style={{ color: "var(--Warning)" }}
                                  >
                                    delete
                                  </button>
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <CustomPagination
                    pageNum={page}
                    handlePageChange={handlePageChange}
                    lastPage={allData.lastPage}
                  />
                </div>
              </>
            ) : null}
          </div>
        )}
        {openDeleteProvider ? (
          <DeleteModal
            title="Provider"
            closeDeleteModal={deleteModal}
            id={providerID}
            showAlertPopup={showAlertPopup}
          />
        ) : null}
        {showAddProvider ? (
          <ProviderFormModal
            title="Add New Provider"
            handleCloseButton={closeModal}
            allData={allProvidersData}
            showAlertPopup={showAlertPopup}
            id={providerID}
          />
        ) : showEditProvider ? (
          <ProviderFormModal
            title="Edit Provider"
            allData={allProvidersData}
            handleCloseButton={closeModal}
            showAlertPopup={showAlertPopup}
            id={providerID}
          />
        ) : null}
        {openPopup ? (
          <AlertPopup
            title={status}
            subTitle={message}
            handleCloseButton={closeModal}
            openPopup={handlePopup}
          />
        ) : null}
      </div>
    </div>
  );
};

export default ProvidersPage;
