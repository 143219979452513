import FullWidthButton from "./fullWidthButton";
import TextInput from "./textInput";
import axios from "../axios";
import React, { useState } from "react";

const ForgetPasswordForm = ({ showAlertPopup }) => {
  const [userName, setUserName] = useState("");
  const handleSubmit = async (e) => {
    e.preventDefault();
    let url = `${axios.defaults.baseURL}/forgetPassword`;

    await axios
      .post(url, {
        email: userName,
      })
      .then((response) => {
        console.log(response)
        showAlertPopup(response.data.status, response.data.message);
        setUserName("");
      })
      .catch((error) => {
        if (error.response) {
          showAlertPopup(
            error.response.data.message,
            error.response.data.data.email
          );
          console.log(error.response);
          console.log("server responded");
        } else if (error.request) {
          console.log("network error");
        } else {
          console.log(error);
        }
      });
  };
  return (
    <div style={style.formContainer}>
      <p className="welcome-text">FORGET PASSWORD </p>
      <p className="subtext">Type your email to send a reset password link</p>

      <form
        className="form"
        action=""
        id="login"
        method="post"
        onSubmit={handleSubmit}
      >
        <TextInput
          label="E-mail"
          value={userName}
          placeholder="Enter your e-mail"
          required={true}
          onChange={(e) => setUserName(e.target.value)}
        />
        <div style={{ marginTop: "20px", width: "100%" }}>
          <FullWidthButton
            disabled={!userName ? true : false}
            text="Submit"
            onClick={handleSubmit}
          />
        </div>
      </form>
    </div>
  );
};

export default ForgetPasswordForm;

const style = {
  formContainer: {
    width: "350px ",
    backgroundColor: "white",
    borderRadius: "10px",
    textAlign: "start",
    display: "flex",
    flexDirection: "column",
    padding: "60px 40px",
  },
};
