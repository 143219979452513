import { useEffect } from "react";

const AlertPopup = ({
  title,
  subTitle,
  openPopup,
  handleLoginButton,
  stopTimer,
}) => {
  useEffect(() => {
    if (!stopTimer) {
      const timer = setTimeout(() => {
        openPopup(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [openPopup, stopTimer]);
  return (
    <div className="alertPopup">
      <button className="closeButton" onClick={() => openPopup(false)}>
        ×
      </button>
      {title === "Success" ? (
        <img
          className="successIcon"
          src={require("../assets/images/successIcon.png")}
          alt="successIcon"
        />
      ) : (
        ""
      )}
      <div className={title === "Success" ? "success" : "alert"}>
        <p style={{ fontSize: "20px", marginBottom: "5px" }}>{title}!</p>
        <p style={{ fontSize: "13px" }}>{subTitle}</p>
        {handleLoginButton && title === "Success" ? (
          <div style={{marginTop: "10px"}}>
            <button className="goToLoginButton" onClick={handleLoginButton}>
              Go To Login
            </button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default AlertPopup;
