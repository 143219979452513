const FilterSelectInput = ({ label, onChange, validation, options, value }) => {
  return (
    <div style={{ maxWidth: "300px" }}>
      <label className="filterSelectLabel" htmlFor={label}>
        {label}
      </label>
      <select className="filterSelectInput" onChange={onChange} value={value}>
        {options.map((option) => {
          return (
            <option key={option.id} value={option.id}>
              {option.name}
            </option>
          );
        })}
      </select>
      {!validation ? "" : <p className="requiredField">{validation}</p>}
    </div>
  );
};

export default FilterSelectInput;
