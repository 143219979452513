import FullWidthButton from "./fullWidthButton";
import TextInput from "./textInput";
import axios from "../axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const LoginForm = ({ type, showAlertPopup }) => {
  const navigate = useNavigate();
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    let url = "";
    type === "admin"
      ? (url = `${axios.defaults.baseURL}/administration/login`)
      : (url = `${axios.defaults.baseURL}/user/login`);
    await axios
      .post(url, {
        email: userName,
        password: password,
        remember_me: rememberMe,
      })
      .then((response) => {
        if (response.status === 200) {
          localStorage.setItem("token", response.data.data.token);
          localStorage.setItem("authenticated", true);
          if (type === "admin") {
            localStorage.setItem("type", type);
            localStorage.setItem(
              "permissions",
              JSON.stringify(response.data.data.permissions)
            );
            localStorage.setItem("userName", response.data.data.name);
            localStorage.setItem("role", response.data.data.role);
            navigate("/admins");
          } else if (response.data.data.user.type === 2) {
            localStorage.setItem("userName", response.data.data.user.name);
            localStorage.setItem("role", response.data.data.user.email);
            localStorage.setItem("type", "manager");
            navigate("/users");
          } else if (response.data.data.user.type === 3) {
            localStorage.setItem("userName", response.data.data.user.name);
            localStorage.setItem("role", response.data.data.user.email);
            localStorage.setItem("type", "user");
            console.log(response.data.data);
            if (response.data.data.complete_data === 1) {
              navigate("/");
            } else if (response.data.data.complete_data === 0) {
              navigate("/registration");
            } else {
              navigate(0);
            }
          }
          navigate(0);
          setPassword("");
          setUserName("");
        }
      })
      .catch((error) => {
        if (error.response) {
          showAlertPopup(
            error.response.data.status,
            error.response.data.message
          );
          console.log(error.response);
          console.log("server responded");
        } else if (error.request) {
          console.log("network error");
        } else {
          console.log(error);
        }
      });
  };
  return (
    <div style={style.formContainer}>
      <p className="welcome-text">WELCOME BACK</p>
      <p className="subtext">Login to your account</p>

      <form
        className="form"
        action=""
        id="login"
        method="post"
        onSubmit={handleSubmit}
      >
        <TextInput
          label="E-mail"
          value={userName}
          placeholder="Enter your e-mail"
          required={true}
          onChange={(e) => setUserName(e.target.value)}
        />
        <TextInput
          label="Password"
          value={password}
          placeholder="Enter your password"
          required={true}
          type="password"
          onChange={(e) => setPassword(e.target.value)}
        />
        <div className="forget-password-container">
          <div>
            <input
              id="rememberMe"
              type="checkbox"
              onChange={(e) => setRememberMe(e.target.checked)}
            />
            <label htmlFor="rememberMe">Remember Me</label>
          </div>
          <p
            onClick={() => navigate("/forget-password")}
            className="forgetPassword"
          >
            Forgot password?
          </p>
        </div>
        <div style={{ marginTop: "50px", width: "100%" }}>
          <FullWidthButton
            disabled={!userName || !password ? true : false}
            text="Login"
            onClick={handleSubmit}
          />
        </div>
      </form>
    </div>
  );
};

export default LoginForm;

const style = {
  formContainer: {
    width: "350px ",
    backgroundColor: "white",
    borderRadius: "10px",
    textAlign: "start",
    display: "flex",
    flexDirection: "column",
    padding: "60px 40px",
  },
};
