import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faArrowRightFromBracket,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import axios from "../axios";

const Header = ({ showLogo = false }) => {
  const [userName, setUserName] = useState("");
  const [role, setRole] = useState("");
  const [openDropDown, setOpenDropDown] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setUserName(localStorage.getItem("userName"));
    setRole(localStorage.getItem("role"));
  }, []);
  const handleDropDown = () => {
    setOpenDropDown((open) => !open);
  };
  const handleLogoutButton = () => {
    let url = `${axios.defaults.baseURL}/administration/logout`;
    axios
      .post(url, {
        headers: {
          Authorization: axios.defaults.token,
        },
      })
      .then((response) => {
        if (response.status === 200) {
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
          console.log("server responded");
        } else if (error.request) {
          console.log("network error");
        } else {
          console.log(error);
        }
      });
    localStorage.removeItem("authenticated");
    localStorage.removeItem("token");
    navigate(0);
  };
  return (
    <div className="headerWrapper">
      <div className="header">
        {showLogo ? (
          <div style={{ flex: "1" }}>
            <img
              src={require("../assets/images/horizontalLogo.png")}
              alt="logo"
              style={{ width: "150px" }}
            />
          </div>
        ) : null}
        <div
          style={{ display: "flex", cursor: "pointer", position: "relative" }}
        >
          <div>
            <p
              style={{
                fontSize: "16px",
                fontWeight: "500",
                color: "var(--text-color)",
              }}
            >
              {userName}
            </p>
            <p style={{ fontSize: "12px" }}>{role}</p>
          </div>
          <FontAwesomeIcon
            icon={faChevronDown}
            style={{ marginLeft: "10px" }}
            onClick={handleDropDown}
          />
          {openDropDown ? (
            <div className="headerDropdown">
              <button
                className="actionButton headerLogoutButton"
                onClick={handleLogoutButton}
              >
                <span>logout</span>
                <FontAwesomeIcon
                  className="logoutSVG"
                  icon={faArrowRightFromBracket}
                />
              </button>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Header;
