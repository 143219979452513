import axios from "../../axios";

const DeleteModal = ({ title, closeDeleteModal, id, showAlertPopup }) => {
  const handleDelete = async () => {
    let url = "";
    switch (title) {
      case "Course":
        url = `${axios.defaults.baseURL}/courses/${id}`;
        break;
      case "Admin":
        url = `${axios.defaults.baseURL}/administration/${id}`;
        break;
      case "Organization":
        url = `${axios.defaults.baseURL}/organizations/${id}`;
        break;
      case "Manager":
        url = `${axios.defaults.baseURL}/managers/${id}`;
        break;
        case "Provider":
        url = `${axios.defaults.baseURL}/courses/providers/${id}`;
        break;
        case "Category":
          url = `${axios.defaults.baseURL}/courses/categories/${id}`;
          break;
      case "User":
        url = `${axios.defaults.baseURL}/users/${id}`;
        break;
      default:
        url = null;
        break;
    }
    axios
      .delete(url, {
        headers: {
          authorization: axios.defaults.token,
        },
      })
      .then((response) => {
        showAlertPopup(response.data.status, response.data.message);
        closeDeleteModal();
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
          console.log("server responded");
        } else if (error.request) {
          console.log("network error");
        } else {
          console.log(error);
        }
      });
  };
  return (
    <div className="modalWrapper">
      <div className="modalContainer">
        <div className="deleteModalContainer">
          <p className="deletModalTitle">Delete {title}?</p>
          <div>
            <button
              onClick={closeDeleteModal}
              className="primaryButton"
              style={{ marginRight: "20px" }}
            >
              Close
            </button>
            <button onClick={handleDelete} className="warningButton">
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteModal;
