const FilterTextInput = ({ label, onChange, validation, value }) => {
  return (
    <div style={{ maxWidth: "300px" }}>
      <label className="filterSelectLabel" htmlFor={label}>
        {label}
      </label>
      <input
        type="text"
        className="filterTextInput"
        onChange={onChange}
        value={value}
      />
      {!validation ? "" : <p className="requiredField">{validation}</p>}
    </div>
  );
};

export default FilterTextInput;
