const FullWidthButton = ({ text, onClick, disabled }) => {
  return (
    <>
      <button className="fullWidthButton" disabled={disabled} onClick={onClick}>
        {text}
      </button>
    </>
  );
};

export default FullWidthButton;
