const TextArea = ({ label, placeholder, required, value, onChange }) => {
  return (
    <div className="text-input">
      <label className="labelStyle" htmlFor="label">
        {label}
      </label>
      <textarea
        rows="2"
        id="label"
        defaultValue={value}
        placeholder={placeholder}
        required={required}
        onChange={onChange}
      />
    </div>
  );
};

export default TextArea;
