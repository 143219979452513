import axios from "../../axios";
import { useMemo, useState } from "react";
import SideBar from "../../components/sideBar/sideBar";
import Header from "../../components/header";
import TitleHeader from "../../components/titleHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AlertPopup from "../../components/alertPopup";
import UploadUserPage from "./uploadUserPage";
import CustomPagination from "../../components/customPagination";
import FilterSelectInput from "../../components/filterSelectInput";
import {
  faFilter,
  faSearch,
  faEllipsisVertical,
  faCheck,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import DeleteModal from "../../components/modals/deleteModal";
import UserFormModal from "../../components/modals/userFormModal";
import BlankPage from "../../components/blankPage";
const UserAdminPage = () => {
  const [allUsersData, setAllUsersData] = useState([]);
  const [showAddUser, setShowAddUser] = useState(false);
  const [showEditUser, setShowEditUser] = useState(false);
  const [message, setMessage] = useState("");
  const [userID, setUserID] = useState("");
  const [status, setStatus] = useState("");
  const [activeStatus, setActiveStatus] = useState("");
  const [editor, setEditor] = useState("");
  const [editorsList, setEditorList] = useState("");
  const [openPopup, setOpenPopup] = useState(false);
  const [openDeleteUser, setOpenDeleteUser] = useState(false);
  const [uploadUser, setUploadUser] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [page, setPage] = useState(1);
  const [allData, setAllData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showAddButton, setShowAddButton] = useState(true);
  const [hideTable, setHideTable] = useState(false);
  const [showFilter, setShowFilter] = useState(false);

  useMemo(() => {
    if (axios.defaults.permissions) {
      if (axios.defaults.permissions.find((e) => e.name === "create_User")) {
        setShowAddButton(true);
      }
    }
    const getUsers = async () => {
      let url = `${axios.defaults.baseURL}/users?filter[name]=${searchInput}&filter[email]=&filter[created_by]=${editor}&filter[check_email_status]=${activeStatus}`;
      await axios
        .get(url, {
          headers: {
            authorization: axios.defaults.token,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            setLoading(false);
            setAllData(response.data.data);
            setAllUsersData(response.data.data.paginatedData);
            setUserID("");
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.message === "Invalid ability provided.") {
              setLoading(false);
              setHideTable(true);
            }
            console.log(error.response);
            console.log("server responded");
          } else if (error.request) {
            console.log("network error");
          } else {
            console.log(error);
          }
        });
    };
    const getLists = async () => {
      await axios
        .get(`${axios.defaults.baseURL}/organization/managers`, {
          headers: {
            authorization: axios.defaults.token,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            setEditorList(response.data.data);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response);
            console.log("server responded");
          } else if (error.request) {
            console.log("network error");
          } else {
            console.log(error);
          }
        });
    };
    getLists();
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showAddUser, openPopup, searchInput, page, activeStatus, editor]);
  const handleVerifiedStatus = (id) => {
    console.log(id);
    axios
      .get(`${axios.defaults.baseURL}/users/resendMail/${id}`, {
        headers: {
          authorization: axios.defaults.token,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          showAlertPopup(response.data.status, response.data.message);
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
          console.log("server responded");
          showAlertPopup(
            error.response.data.status,
            error.response.data.message
          );
        } else if (error.request) {
          console.log("network error");
        } else {
          console.log(error);
        }
      });
  };
  const addNewUser = () => {
    setShowAddUser((current) => !current);
  };
  const closeModal = () => {
    setShowAddUser(false);
    setShowEditUser(false);
    setUserID("");
  };
  const showAlertPopup = (status, message) => {
    handlePopup(true);
    setMessage(message);
    setStatus(status);
  };
  const handlePopup = (value) => {
    setOpenPopup(value);
  };
  const handleUploadUser = () => {
    setUploadUser((current) => !current);
  };
  const handleEditButton = (id) => {
    setUserID(id);
    setShowEditUser(true);
  };
  const handleDeleteButton = (id) => {
    deleteModal();
    setUserID(id);
  };
  const deleteModal = () => {
    setOpenDeleteUser((current) => !current);
  };
  const handleFilterButton = () => {
    setShowFilter((current) => !current);
    if (showFilter === true) {
      setActiveStatus("");
    }
  };
  const options = [
    { name: "All", id: "" },
    { name: "Blocked", id: 0 },
    { name: "Active", id: 1 },
  ];
  const handlePageChange = (e) => {
    setPage(e);
  };
  return (
    <div style={{ display: "flex" }}>
      <SideBar />
      {/* mainContainer*/}
      <div className="mainContainer">
        <Header />
        {loading ? (
          <p
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "white",
              height: "500px",
            }}
          >
            loading...
          </p>
        ) : !allUsersData.length &&
          !loading &&
          !uploadUser &&
          !editor &&
          !searchInput &&
          !activeStatus ? (
          <div style={{ backgroundColor: "white" }}>
            <TitleHeader
              title="Manage Users"
              subTitle="Users"
              showAddButton={false}
              mainButtonFunction={addNewUser}
              secondaryButton="Upload"
              secondaryButtonFunction={handleUploadUser}
              counterNumber={allData.total}
            />
            <BlankPage
              mainButton="Add New"
              mainText="Let’s get you started!"
              paragraph="You can add a new user or upload multiple users at once."
              showAddButton={showAddButton}
              mainButtonFunction={addNewUser}
              secondaryButton="Upload"
              secondaryButtonFunction={handleUploadUser}
            />
          </div>
        ) : !uploadUser ? (
          <div>
            <TitleHeader
              title="Manage Users"
              subTitle="Users"
              mainButton="Add New"
              showAddButton={showAddButton}
              mainButtonFunction={addNewUser}
              secondaryButton="Upload"
              secondaryButtonFunction={handleUploadUser}
              counterNumber={allData.total}
            />
            {!hideTable ? (
              <>
                <div className="filterHeader">
                  <div style={{ padding: "0 30px", display: "flex" }}>
                    <div style={{ position: "relative", marginRight: "16px" }}>
                      <FontAwesomeIcon icon={faSearch} className="searchIcon" />
                      <input
                        type="text"
                        value={searchInput}
                        onChange={(e) => setSearchInput(e.target.value)}
                        className="searchInput"
                        placeholder="Search Users"
                      />
                    </div>
                    {!showFilter ? (
                      <button
                        className="filterButton"
                        onClick={handleFilterButton}
                      >
                        <FontAwesomeIcon
                          icon={faFilter}
                          style={{ marginRight: "10px" }}
                        />
                        Filter
                      </button>
                    ) : (
                      <button
                        className="filterButton"
                        onClick={handleFilterButton}
                      >
                        <FontAwesomeIcon
                          icon={faXmark}
                          style={{ marginRight: "10px" }}
                        />
                        Reset
                      </button>
                    )}
                  </div>
                </div>
                <div className="mainTable">
                  {showFilter ? (
                    <div className="filterInputsContainer">
                      <FilterSelectInput
                        label="Editor"
                        value={editor}
                        options={editorsList}
                        onChange={(e) => {
                          setEditor(e.target.value);
                        }}
                      />
                      <FilterSelectInput
                        label="Status"
                        value={activeStatus}
                        options={options}
                        onChange={(e) => {
                          setActiveStatus(e.target.value);
                        }}
                      />
                    </div>
                  ) : null}
                  <table style={{ width: "100%" }}>
                    <tbody>
                      <tr className="tableHeaderContainer">
                        <th>User Name</th>
                        <th>E-Mail</th>
                        <th>Editor</th>
                        <th>Status</th>
                        <th>Actions</th>
                      </tr>
                      {allUsersData.map((e) => {
                        return (
                          <tr key={e.id}>
                            <td>{e.name}</td>
                            <td>{e.email}</td>
                            <td>{e.editor}</td>
                            <td>
                              {e.verified === "Verified" ? (
                                <div className="approvedText">
                                  <FontAwesomeIcon icon={faCheck} />
                                  Verified
                                </div>
                              ) : (
                                <button
                                  onClick={() => {
                                    handleVerifiedStatus(e.id);
                                  }}
                                  className="sendEmailButton"
                                >
                                  Re-send
                                </button>
                              )}
                            </td>
                            <td>
                              <div className="actionWrapper">
                                <FontAwesomeIcon icon={faEllipsisVertical} />
                                <div className="actionButtonsContainer">
                                  <button
                                    onClick={() => handleEditButton(e.id)}
                                    className="actionButton"
                                  >
                                    edit
                                  </button>

                                  <button
                                    onClick={() => handleDeleteButton(e.id)}
                                    className="actionButton"
                                    style={{ color: "var(--Warning)" }}
                                  >
                                    delete
                                  </button>
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <CustomPagination
                    pageNum={page}
                    handlePageChange={handlePageChange}
                    lastPage={allData.lastPage}
                  />
                </div>
              </>
            ) : null}
          </div>
        ) : (
          <UploadUserPage
            handleUploadUser={handleUploadUser}
            closeUploadPage={handleUploadUser}
            showPopup={showAlertPopup}
          />
        )}
        {openDeleteUser ? (
          <DeleteModal
            title="User"
            closeDeleteModal={deleteModal}
            id={userID}
            showAlertPopup={showAlertPopup}
          />
        ) : null}
        {showAddUser ? (
          <UserFormModal
            title="Add New User"
            handleCloseButton={closeModal}
            allData={allUsersData}
            showAlertPopup={showAlertPopup}
            id={userID}
          />
        ) : showEditUser ? (
          <UserFormModal
            title="Edit User"
            allData={allUsersData}
            handleCloseButton={closeModal}
            showAlertPopup={showAlertPopup}
            id={userID}
          />
        ) : null}
        {openPopup ? (
          <AlertPopup
            title={status}
            subTitle={message}
            handleCloseButton={closeModal}
            openPopup={handlePopup}
          />
        ) : null}
      </div>
    </div>
  );
};

export default UserAdminPage;
