import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faAngleRight,
  faAnglesLeft,
  faAnglesRight,
} from "@fortawesome/free-solid-svg-icons";

const CustomPagination = ({ pageNum, handlePageChange, lastPage }) => {
  const goToFirstPage = () => {
    handlePageChange(1);
  };
  const goToLastPage = () => {
    handlePageChange(lastPage);
  };
  const nextPage = () => {
    if (pageNum < lastPage) {
      handlePageChange(pageNum + 1);
    }
  };
  const perviousPage = () => {
    if (pageNum > 1) {
      handlePageChange(pageNum - 1);
    }
  };
  return (
    <div className="paginationContainer">
      <p>
        page {pageNum} of {lastPage}
      </p>
      <button onClick={goToFirstPage} className="paginationButton">
        <FontAwesomeIcon icon={faAnglesLeft} />
      </button>
      <button onClick={perviousPage} className="paginationButton">
        <FontAwesomeIcon icon={faAngleLeft} />
      </button>
      <button onClick={nextPage} className="paginationButton">
        <FontAwesomeIcon icon={faAngleRight} />
      </button>
      <button onClick={goToLastPage} className="paginationButton">
        <FontAwesomeIcon icon={faAnglesRight} />
      </button>
    </div>
  );
};

export default CustomPagination;
