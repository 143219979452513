import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faArrowUpFromBracket,
} from "@fortawesome/free-solid-svg-icons";
const TitleHeader = ({
  title,
  subTitle,
  counterNumber,
  mainButton,
  secondaryButton,
  mainButtonFunction,
  secondaryButtonFunction,
  hideSecondaryButton,
  showAddButton = true,
}) => {
  return (
    <div
      style={{
        height: "100px",
        display: "flex",
        justifyContent: "space-between",
        padding: "0px 30px",
        alignItems: "center",
      }}
    >
      <div style={{ color: " var(--c600)" }}>
        <h1 style={{ fontSize: "30px" }}>
          {title} <span>{counterNumber && `(${counterNumber})`}</span>
        </h1>
        <p>{subTitle}</p>
      </div>
      {showAddButton ? (
        <div>
          <button
            onClick={mainButtonFunction}
            className="primaryButton"
            style={
              hideSecondaryButton
                ? { marginRight: "0px" }
                : { marginRight: "10px" }
            }
          >
            <FontAwesomeIcon icon={faPlus} style={{ marginRight: "10px" }} />
            {mainButton}
          </button>
          {!hideSecondaryButton ? (
            <button
              onClick={secondaryButtonFunction}
              className="secondaryButton"
            >
              <FontAwesomeIcon
                icon={faArrowUpFromBracket}
                style={{ marginRight: "10px" }}
              />
              {secondaryButton}
            </button>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export default TitleHeader;
