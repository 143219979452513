import axios from "axios";
const token = localStorage.getItem("token");
let permissions = [];
if(localStorage.getItem("permissions") !== "undefined"){
  permissions = JSON.parse(localStorage.getItem("permissions"));
}
export default axios.create({
  baseURL: "https://edumart.talentstech.co/api",
  importURL: "https://edumart.talentstech.co/imports",
  token: `Bearer ${token}`,
  permissions: permissions,
});
