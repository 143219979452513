const TextInput = ({
  label,
  placeholder,
  required,
  value,
  onChange,
  validation,
  type,
  style,
}) => {
  return (
    <div className="text-input">
      <label className="labelStyle" htmlFor="label">
        {label}
      </label>
      <input
        style={style}
        type={type === "password" ? "password" : "text"}
        id={label}
        value={value}
        placeholder={placeholder}
        required={required}
        onChange={onChange}
      />
      {!validation ? "" : <p className="requiredField">{validation}</p>}
    </div>
  );
};

export default TextInput;
