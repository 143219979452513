import { useEffect, useState } from "react";
import axios from "../../axios";
import TextInput from "../textInput";
const UserFormModal = ({
  title,
  handleCloseButton,
  showAlertPopup,
  id,
  allData,
}) => {
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [loading, setLoading] = useState(true);
  const [nameValidation, setNameValidation] = useState(false);
  const [emailValidation, setEmailValidation] = useState(false);
  const [userID, setUserID] = useState("");

  useEffect(() => {
    if (id) {
      setUserID(id);
      handleDefaultValue();
    setLoading(false);
    }
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleDefaultValue = () => {
    let allDataArray = allData;
    let itemData = allDataArray.find((itemID) => itemID.id === id);
    setUserName(itemData.name);
    setUserEmail(itemData.email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let url = "";
    let axiosBody = {};
    userID
      ? (url = `${axios.defaults.baseURL}/users/${userID}`)
      : (url = `${axios.defaults.baseURL}/users`);

    userID
      ? (axiosBody = {
          name: userName,
          email: userEmail,
          isActive: 1,
          _method: "put",
        })
      : (axiosBody = {
          name: userName,
          email: userEmail,
        });
    await axios
      .post(url, axiosBody, {
        headers: {
          authorization: axios.defaults.token,
        },
      })
      .then((response) => {
        if (response.status === 202) {
          handleCloseButton();
          setUserName("");
          setUserEmail("");
          showAlertPopup(response.data.status, response.data.message);
        } else if (response.status === 200) {
          handleCloseButton();
          setUserName("");
          setUserEmail("");
          showAlertPopup(response.data.status, response.data.message);
        }
      })
      .catch((error) => {
        if (error.response) {
          showAlertPopup(
            error.response.data.status,
            error.response.data.message
          );
          console.log(error.response);
          setNameValidation(error.response.data.data.name);
          setEmailValidation(error.response.data.data.email);
          console.log("server responded");
        } else if (error.request) {
          console.log("network error");
        } else {
          console.log(error);
        }
      });
  };
  return (
    <div className="modalWrapper">
      <div className="modalContainer">
        <div className="formContainer">
          <p className="welcome-text">{title}</p>
          <form className="form" method="post">
            {!loading ? (
              <>
                <TextInput
                  label="User Name"
                  value={userName}
                  placeholder="User Name"
                  required={true}
                  validation={nameValidation}
                  onChange={(e) => {
                    setUserName(e.target.value);
                  }}
                />
                <TextInput
                  label="Email"
                  value={userEmail}
                  placeholder="Email"
                  required={true}
                  validation={emailValidation}
                  onChange={(e) => {
                    setUserEmail(e.target.value);
                  }}
                />
               
              </>
            ) : null}
            <div
              className="modalFormButtons"
              style={{ marginTop: "20px", width: "100%" }}
            >
              <button className="secondaryButton" onClick={handleCloseButton}>
                Cancel
              </button>
              <button
                className="primaryButton"
                type="submit"
                onClick={handleSubmit}
              >
                {userID ? "Update" : "Add"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UserFormModal;
