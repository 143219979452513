const NotFoundPage = () => {
  return (
    <div
      style={{
        display: "grid",
        alignSelf: "center",
        placeItems: "center",
        height: "100vh",
        fontSize: "45px",
        color: "var(--Shade)"
      }}
    >
      <h1>Page Not Found</h1>
    </div>
  );
};

export default NotFoundPage;
