import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faArrowUpFromBracket,
} from "@fortawesome/free-solid-svg-icons";

const BlankPage = ({
  mainButton,
  secondaryButton,
  mainButtonFunction,
  secondaryButtonFunction,
  hideSecondaryButton,
  showAddButton = true,
  mainText,
  paragraph,
}) => {
  return (
    <div className="adminBlankPageContainer">
      <h2>{mainText}</h2>
      <p>{paragraph}</p>
      <img
        src={require("../assets/images/noDataImage.png")}
        alt="page is empty"
        style={{ maxWidth: "350px", marginTop: "20px" }}
      />
      {showAddButton ? (
        <div style={{ marginTop: "20px" }}>
          <button
            onClick={mainButtonFunction}
            className="primaryButton"
            style={
              hideSecondaryButton
                ? { marginRight: "0px" }
                : { marginRight: "10px" }
            }
          >
            <FontAwesomeIcon icon={faPlus} style={{ marginRight: "10px" }} />
            {mainButton}
          </button>
          {!hideSecondaryButton ? (
            <button
              onClick={secondaryButtonFunction}
              className="secondaryButton"
            >
              <FontAwesomeIcon
                icon={faArrowUpFromBracket}
                style={{ marginRight: "10px" }}
              />
              {secondaryButton}
            </button>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export default BlankPage;
