import axios from "../../axios";
import { FileDrop } from "react-file-drop";
import { useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faDownload } from "@fortawesome/free-solid-svg-icons";

const UploadCoursesPage = ({
  handleUploadCourse,
  closeUploadPage,
  showPopup,
}) => {
  const [dregFileVisited, setDregFileVisited] = useState(false);

  const inputRef = useRef(null);
  const filePicker = () => {
    inputRef.current.click();
  };

  const fileHandler = async (files) => {
    await axios
      .post(
        `${axios.defaults.baseURL}/courses/import`,
        { file: files[0] },
        {
          headers: {
            "Content-Type": "multipart/form-data",
            authorization: axios.defaults.token,
          },
        }
      )
      .then((response) => {
        if (response.status === 201) {
          console.log(files[0], response);
          closeUploadPage(false);
          showPopup(response.data.status, response.data.message);
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
          showPopup(error.response.data.status, error.response.data.message);
          console.log("server responded");
        } else if (error.request) {
          console.log("network error");
        } else {
          console.log(error);
        }
      });
  };

  return (
    <div
      style={{
        backgroundColor: "white",
        padding: "0px 30px",
        paddingBottom: "50px",
      }}
    >
      <div>
        <div style={{ display: "flex" }}>
          <FontAwesomeIcon
            onClick={handleUploadCourse}
            style={{
              fontSize: "20px",
              color: " var(--c600)",
              marginRight: "10px",
              height: "35px",
              width: "13px",
            }}
            icon={faChevronLeft}
          />
          <div>
            <h1 style={{ color: " var(--c600)", fontSize: "30px" }}>
              Upload bulk courses
            </h1>
            <p>Courses / Upload </p>
          </div>
        </div>
      </div>
      <div style={{ marginTop: "50px" }} className="uploadSectionContainer">
        <div>
          <p>Step 1</p>
          <p>Download the template & fill the required data.</p>
        </div>
        <a
          href={`${axios.defaults.importURL}/courses.csv`}
          className="primaryButton"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <FontAwesomeIcon icon={faDownload} style={{ marginRight: "10px" }} />
          Download
        </a>
      </div>

      <div className="uploadSectionContainer">
        <div style={{ width: "100%" }}>
          <p>Step 2</p>
          <p>
            After completing the template file with all the necessary
            information, upload it here to be placed on your table.
          </p>
          <div>
            <div className="dragDropWrapper">
              <FileDrop
                onTargetClick={(event) => filePicker(event)}
                className={
                  dregFileVisited ? "dragDropContainer" : "LeaveDropContainer"
                }
                onDragOver={() => setDregFileVisited(true)}
                onDragLeave={() => setDregFileVisited(false)}
                onDrop={(f) => {
                  fileHandler(f);
                  setDregFileVisited(false);
                }}
              >
                <input
                  onChange={(e) => fileHandler(e.target.files)}
                  className="fileInput"
                  value=""
                  ref={inputRef}
                  type="file"
                  accept=".csv"
                />
                Drag CSV file here!
              </FileDrop>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadCoursesPage;
