import Vector from "../../assets/images/loginVector.svg";
import Vector2 from "../../assets/images/loginVector2.svg";
import PasswordForm from "../../components/passrowdForm";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import "./style.css";
import AlertPopup from "../../components/alertPopup";
const PasswordPage = () => {
  const navigate = useNavigate();
  const [openPopup, setOpenPopup] = useState(false);
  const [status, setStatus] = useState("");
  const [message, setMessage] = useState("");

  const { token } = useParams();
  const handlePopup = (value) => {
    setOpenPopup(value);
  };
  const showAlertPopup = (status, message) => {
    handlePopup(true);
    setMessage(message);
    setStatus(status);
  };
  const handleLoginButton = () => {
    navigate("/registration");
  };
  return (
    <div>
      <img
        className="logoImage"
        src={require("../../assets/images/logo.png")}
        alt="logo"
      />
      <img
        src={Vector}
        alt=""
        style={{
          width: "695px",
          height: "110vh",
          objectFit: "cover",
          position: "absolute",
          zIndex: "-1",
          top: "-10vh",
        }}
      />
      <img
        src={Vector2}
        alt=""
        style={{
          width: "9vw",
          bottom: "0",
          position: "absolute",
          zIndex: "-1",
        }}
      />
      <img
        style={{
          position: "absolute",
          bottom: "2rem",
          left: "1rem",
          width: "5rem",
        }}
        src={require("../../assets/images/Plant.png")}
        alt="Plant"
      />
      <div className="page-wrapper">
        <PasswordForm token={token} showAlertPopup={showAlertPopup} />
        <img
          className="login-avatar"
          src={require("../../assets/images/loginAvatar.png")}
          alt="login avatar"
        />
      </div>
      {openPopup ? (
        <AlertPopup
          title={status}
          subTitle={message}
          openPopup={handlePopup}
          handleLoginButton={handleLoginButton}
          stopTimer={true}
        />
      ) : null}
    </div>
  );
};

export default PasswordPage;
