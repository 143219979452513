import { useEffect, useState } from "react";
import axios from "../../axios";
import TextInput from "../textInput";
import SelectInput from "../selectInput";
const ManagersFormModal = ({
  title,
  handleCloseButton,
  showAlertPopup,
  id,
  allData,
  organization,
}) => {
  const [managerName, setManagerName] = useState("");
  const [managerEmail, setManagerEmail] = useState("");
  const [managerOrganization, setManagerOrganization] = useState(
    organization[0].id
  );
  const [loading, setLoading] = useState(true);
  const [nameValidation, setNameValidation] = useState(false);
  const [emailValidation, setEmailValidation] = useState(false);
  const [isActive, setIsActive] = useState(0);
  const [managerID, setManagerID] = useState("");

  useEffect(() => {
    if (id) {
      setManagerID(id);
      handleDefaultValue();
    }
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleDefaultValue = () => {
    let allDataArray = allData;
    let itemData = allDataArray.find((itemID) => itemID.id === id);
    setManagerName(itemData.name);
    setManagerEmail(itemData.email);
    itemData.status === "active" ? setIsActive(1) : setIsActive(0);
    setManagerOrganization(organization[0].id);
  };
  const options = [
    { name: "Blocked", id: 0 },
    { name: "Active", id: 1 },
  ];
  const handleSubmit = async (e) => {
    e.preventDefault();
    let url = "";
    let axiosBody = {};
    managerID
      ? (url = `${axios.defaults.baseURL}/managers/${managerID}`)
      : (url = `${axios.defaults.baseURL}/managers`);

    managerID
      ? (axiosBody = {
          name: managerName,
          email: managerEmail,
          organization_id: managerOrganization,
          isActive: isActive,
          _method: "put",
        })
      : (axiosBody = {
          name: managerName,
          email: managerEmail,
          organization_id: managerOrganization,
        });
    await axios
      .post(url, axiosBody, {
        headers: {
          authorization: axios.defaults.token,
        },
      })
      .then((response) => {
        if (response.status === 202) {
          handleCloseButton();
          setManagerName("");
          setManagerEmail("");
          setManagerOrganization("");
          showAlertPopup(response.data.status, response.data.message);
        } else if (response.status === 200) {
          handleCloseButton();
          setManagerName("");
          setManagerEmail("");
          setManagerOrganization("");
          showAlertPopup(response.data.status, response.data.message);
        }
      })
      .catch((error) => {
        if (error.response) {
          showAlertPopup(
            error.response.data.status,
            error.response.data.message
          );
          console.log(error.response);
          setNameValidation(error.response.data.data.name);
          setEmailValidation(error.response.data.data.email);
          console.log("server responded");
        } else if (error.request) {
          console.log("network error");
        } else {
          console.log(error);
        }
      });
  };
  return (
    <div className="modalWrapper">
      <div className="modalContainer">
        <div className="formContainer">
          <p className="welcome-text">{title}</p>
          <form className="form" method="post">
            {!loading ? (
              <>
                <TextInput
                  label="Manager Name"
                  value={managerName}
                  placeholder="Manager Name"
                  required={true}
                  validation={nameValidation}
                  onChange={(e) => {
                    setManagerName(e.target.value);
                  }}
                />
                <TextInput
                  label="Email"
                  value={managerEmail}
                  placeholder="Email"
                  required={true}
                  validation={emailValidation}
                  onChange={(e) => {
                    setManagerEmail(e.target.value);
                  }}
                />
                <SelectInput
                  label="Manager Organization"
                  value={managerOrganization}
                  options={organization}
                  onChange={(e) => setManagerOrganization(e.target.value)}
                />
                {id ? (
                  <SelectInput
                    label="Status"
                    value={isActive}
                    options={options}
                    onChange={(e) => setIsActive(e.target.value)}
                  />
                ) : null}
              </>
            ) : null}
            <div
              className="modalFormButtons"
              style={{ marginTop: "20px", width: "100%" }}
            >
              <button className="secondaryButton" onClick={handleCloseButton}>
                Cancel
              </button>
              <button
                className="primaryButton"
                type="submit"
                onClick={handleSubmit}
              >
                {managerID ? "Update" : "Add"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ManagersFormModal;
