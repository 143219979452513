import Vector from "../../assets/images/rigVector.png";
import Vector2 from "../../assets/images/rigVector2.png";
import AlertPopup from "../../components/alertPopup";
import { useState } from "react";
import "./style.css";
import RegistrationForm from "../../components/registrationForm";
const RegistrationPage = ({ type }) => {
  const [openPopup, setOpenPopup] = useState(false);
  const [status, setStatus] = useState("");
  const [message, setMessage] = useState("");
  const handlePopup = (value) => {
    setOpenPopup(value);
  };
  const showAlertPopup = (status, message) => {
    handlePopup(true);
    setMessage(message);
    setStatus(status);
  };
  return (
    <div>
      <img
        className="logoImage"
        src={require("../../assets/images/logo.png")}
        alt="logo"
      />
      <img
        src={Vector}
        alt=""
        style={{
          width: "570px",
          height: "829px",
          objectFit: "fill",
          position: "absolute",
          zIndex: "-1",
          bottom: "-79px",
          right: "0px",
        }}
      />
      <img
        src={Vector2}
        alt=""
        style={{
          width: "700px",
          top: "0",
          position: "absolute",
          zIndex: "-1",
        }}
      />
      <div className="page-wrapper">
        <RegistrationForm type={type} showAlertPopup={showAlertPopup} />
        <img
          className="register-avatar"
          src={require("../../assets/images/rigAvatar.png")}
          alt="login avatar"
        />
      <div style={{width:"35%"}}></div>
      </div>
      {openPopup ? (
        <AlertPopup title={status} subTitle={message} openPopup={handlePopup} />
      ) : null}
    </div>
  );
};

export default RegistrationPage;
