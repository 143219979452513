import TextArea from "./textArea";

const PopupModal = ({
  title,
  openPopup,
  note,
  setNote,
  handleStatus,
  ...props
}) => {

  const handleSubmit = (e) => {
    e.preventDefault();
    handleStatus();
    openPopup(false);
  };
  return (
    <div className="alertPopup modal">
      <button className="closeButton" onClick={() => openPopup(false)}>
        ×
      </button>

      <div>
        <p style={{ fontSize: "20px", marginBottom: "20px" }}>{title}</p>
        <form onSubmit={handleSubmit}>
          <TextArea onChange={(e) => setNote(e.target.value)} value={note} />
          <button style={{ marginTop: "20px" }} className="primaryButton">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default PopupModal;
