import { useEffect, useMemo, useState } from "react";
import axios from "../../axios";
import TextArea from "../textArea";
import TextInput from "../textInput";
import SelectInput from "../selectInput";
const CoursesFormModal = ({
  title,
  handleCloseButton,
  showAlertPopup,
  id,
  lists,
  allData,
}) => {
  const [courseName, setCourseName] = useState("");
  const [provider, setProvider] = useState("");
  const [duration, setDuration] = useState("");
  const [price, setPrice] = useState("");
  const [address, setAddress] = useState("");
  const [level, setLevel] = useState("");
  const [category, setCategory] = useState("");
  const [description, setDescription] = useState("");
  const [nameValidation, setNameValidation] = useState(false);
  const [durationValidation, setDurationValidation] = useState(false);
  const [priceValidation, setPriceValidation] = useState(false);
  const [courseID, setCourseID] = useState("");
  useMemo(() => {
    setCourseID(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    setCategory(lists.categories[0].id);
    setProvider(lists.providers[0].id);
    setLevel(lists.levels[0].id);
    if (courseID) {
      handleDefaultValue();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleDefaultValue = () => {
    let allDataArray = allData;
    let itemData = allDataArray.find((itemID) => itemID.id === id);

    setCourseName(itemData.title);
    setProvider(itemData.provider_id);
    setDuration(itemData.duration);
    setPrice(itemData.price);
    setAddress(itemData.location);
    setLevel(itemData.level_id);
    setCategory(itemData.category_id);
    setDescription(itemData.description);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    let url = "";
    let axiosBody = {};
    courseID
      ? (url = `${axios.defaults.baseURL}/courses/${courseID}`)
      : (url = `${axios.defaults.baseURL}/courses`);

    courseID
      ? (axiosBody = {
          title: courseName,
          duration: duration,
          price: price,
          provider_id: provider,
          location: address,
          level_id: level,
          category_id: category,
          desc: description,
          _method: "put",
        })
      : (axiosBody = {
          title: courseName,
          duration: duration,
          price: price,
          location: address,
          provider_id: provider,
          level_id: level,
          category_id: category,
          desc: description,
        });
    await axios
      .post(url, axiosBody, {
        headers: {
          authorization: axios.defaults.token,
        },
      })
      .then((response) => {
        if (response.status === 201) {
          handleCloseButton();
          setCourseName("");
          setProvider("");
          setDuration("");
          setPrice("");
          setLevel("");
          setAddress("");
          setCategory("");
          setDescription("");
          showAlertPopup(response.data.status, response.data.message);
        } else if (response.status === 202) {
          handleCloseButton();
          setCourseName("");
          setProvider("");
          setDuration("");
          setPrice("");
          setLevel("");
          setAddress("");
          setCategory("");
          setDescription("");
          showAlertPopup(response.data.status, response.data.message);
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
          setNameValidation(error.response.data.data.title);
          setDurationValidation(error.response.data.data.duration);
          setPriceValidation(error.response.data.data.price);
          console.log("server responded");
        } else if (error.request) {
          console.log("network error");
        } else {
          console.log(error);
        }
      });
  };

  return (
    <div className="modalWrapper">
      <div className="modalContainer">
        <div className="formContainer">
          <p className="welcome-text">{title}</p>
          <form className="form" method="post">
            <TextInput
              label="Course Name"
              value={courseName}
              placeholder="Course Name"
              required={true}
              validation={nameValidation}
              onChange={(e) => {
                setCourseName(e.target.value);
              }}
            />
            <SelectInput
              label="Provider"
              value={provider}
              options={lists.providers}
              onChange={(e) => setProvider(e.target.value)}
            />
            <TextInput
              label="Duration"
              value={duration}
              placeholder="Duration"
              required={true}
              validation={durationValidation}
              onChange={(e) => setDuration(e.target.value)}
            />
            <TextInput
              label="Price"
              value={price}
              placeholder="Price"
              required={true}
              validation={priceValidation}
              onChange={(e) => setPrice(e.target.value)}
            />
            <TextInput
              label="Address"
              value={address}
              placeholder="Address"
              required={true}
              onChange={(e) => setAddress(e.target.value)}
            />
            <SelectInput
              label="Level"
              value={level}
              options={lists.levels}
              onChange={(e) => setLevel(e.target.value)}
            />
            <SelectInput
              label="category"
              value={category}
              options={lists.categories}
              onChange={(e) => setCategory(e.target.value)}
            />
            <TextArea
              label="Description"
              value={description}
              placeholder="Description"
              required={false}
              onChange={(e) => setDescription(e.target.value)}
            />
            <div
              className="modalFormButtons"
              style={{ marginTop: "20px", width: "100%" }}
            >
              <button className="secondaryButton" onClick={handleCloseButton}>
                Cancel
              </button>
              <button
                className="primaryButton"
                type="submit"
                onClick={handleSubmit}
              >
                {courseID ? "Update" : "Add"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CoursesFormModal;
